.pdfAuditoriaImageRotate {
  padding: 0% 0% 0% 12%;
  object-fit: cover;
  margin-bottom: 150px;
}

.imageContainer {
  display: flex;
}

.logoPdfAuditoria {
  display: flex;
  height: 100px !important;
  width: 300px;
}

/* Tabela */
.celPlacaAud {
  min-width: 4.5rem;
  min-height: 5rem;
}

.celLatitudeAud {
  min-width:  10rem;
  min-height: 5rem;
}

.celLongitudeAud {
  min-width: 10rem;
  min-height: 5rem;
}

.celMotorAud {
  min-width: 2.5rem;
  min-height: 5rem;
}

.celVelocidadeAud {
  min-width: 3rem;
  min-height: 5rem;
}

.celPosicaoAud {
  min-width: 7rem;
  min-height: 5rem;
}

.celLogradouroAud {
  min-width: 10.5rem;
  min-height: 5rem;
}

/* cabeçalho da ta bela */

.cabPlacaAud {
  min-width: 3rem;

}

.cabLatitudeAud {
  min-width:  10rem;
}

.cabLongitudeAud {
  min-width: 10rem;
}

.cabMotorAud {
  min-width: 2.5rem;
}

.cabVelocidadeAud {
  min-width: 3rem;
}

.cabPosicaoAud {
  min-width: 7rem;
}

.cabLogradouroAud {
  min-width: 10.5rem;
}
