/* Headers da tabela */
.headPlacaRaio {
  min-width: 6.6rem;
}

.headLatRaio {
  min-width: 10rem;
}

.headLngRaio {
  min-width: 10.1rem;
}

.headMotorRaio {
  min-width: 5.1rem;
}

.headVelRaio {
  min-width: 7rem;
}

.headDtPosRaio {
  min-width: 9rem;
}

/* Células da tabela */
.celPlacaRaio {
  min-width: 5.2rem;
}

.celLatRaio {
  min-width: 10rem;
}

.celLngRaio {
  min-width: 10rem;
}

.celMotorRaio {
  width: 7rem;
}

.celVelRaio {
  min-width: 7rem;
}

.celDtPosRaio {
  min-width: 7.5rem;
  border-right: 5rem;
}
