.wrapCollabsible {
  margin-bottom: 1.2rem 0;
}

input[type='checkbox'] {
  display: none;
}

.lblToggle {
  height: 125px;
  width: 100%;
  display: flex;
  font-size: 1.2rem;
  text-align: center;
  padding: 1rem;
  color: #4d4d4d;
  background: #fff;
  cursor: pointer;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  transition: all 0.5s ease-out;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
}

.lblToggle:hover {
  color: #4d4d4d;
}

.dadosOcorrencia {
  display: flex;
}

.tipoOcorrencia {
  color: #fff;
  font-weight: 500;
  margin-right: 10px;
}

/* .lblToggle::before {
    content: ' ';
    display: inline-block;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    vertical-align: middle;
    margin-right: .7rem;
    transform: translateY(-2px);
    transition: transform .2s ease-out;
} */

.contentInner {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 0.5rem 1rem;
}

.header {
  display: flex;
}

.icon {
  height: 50px;
  width: 50px;
  fill: #18a779;
}

.rowTitleIcon {
  height: 30px;
  width: 30px;
  fill: #18a779;
}

.cercasIcon {
  margin-left: 0.7rem;
}

.rowActionsIcon {
  height: 25px;
  width: 25px;
  fill: #a3a5a4;
  text-align: center;
  margin-left: 1rem;
}

.rowTitle {
  font-weight: 500;
  color: var(--corFundo4);
  font-size: 1.1rem;
  text-align: center;
  margin-left: 0.5rem;
}

.buttonG {
  border: none;
  background: none;
}

.row {
  display: flex;
  width: 100%;
  margin-bottom: 6px;
}

.collum {
  text-align: center;
  width: 16%;
  align-items: center;
  margin: auto;
}

.tableCol {
  align-items: left;
  margin: auto;
}

.containerModal {
  align-items: center;
  justify-content: center;
}

.rowDadosVeiculo {
  display: flex;
  width: 100%;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 5px;
}

.collumDadosVeiculo {
  text-align: left;
  justify-content: space-between;
  width: 50%;
}

.dadosVeiculo {
  font-size: 1.5rem;
  color: rgb(75, 75, 75);
  padding-top: 10px;
  font-weight: 500;
}

.containerSearch {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.search {
  width: 55%;
  margin-right: 10px;
}

.title {
  padding: 5px;
  font-size: 1rem;
  color: rgb(75, 75, 75);
  font-weight: 700;
  background: rgb(236, 236, 236);
}

.descricao {
  padding: 10px 5px;
  font-weight: 500;
}

.dadoInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tipo {
  width: 15%;
  color: var(--corFundo4);
  text-align: center;
}

.dadoLocal {
  width: 60%;
  margin-left: 10px;
}

.chave {
  color: #4d4d4d;
}

.valor {
  font-weight: 500;
  color: var(--corFundo4);
  font-size: 1.1rem;
  text-align: center;
}

.tipoLocal {
  color: var(--corFundo4);
  text-align: center;
}

.localValor {
  display: block;
  font-weight: 500;
  color: var(--corFundo4);
  font-size: 1.1rem;
  text-align: center;
}

.divider {
  display: block;
  border-bottom: 0.1rem solid #cecece;
}

.toggle:checked + .lblToggle::before {
  transform: rotate(90deg) translateX(-3px);
}

.collapsibleContent {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
}

.toggle:checked + .lblToggle + .collapsibleContent {
  max-height: 100vh;
}

.toggle:checked + .lblToggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.adminButtons {
  display: flex;
  flex-direction: row;
}

.rangePicker {
  border-radius: 10px !important;
  height: 40px;
}

@media (max-width: 800px) {
  .hide {
    display: none !important;
  }

  .adminButtons {
    flex-direction: column;
  }
}

