.row {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.container {
  margin: 0px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.campos {
  width: 30%;
  margin-right: 20px;

  height: 50vh;
}

.campoMap {
  min-width: 300px;
  min-height: 40vh;
  width: 65%;

  height: 60vh;
}

.containerSolicitante {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.input {
  border-radius: 10px !important;
  height: 40px;
}

.selectOfCerca {
  border-radius: 10px !important;
}

.rangePicker {
  border-radius: 10px !important;
  height: 40px;
}

.descrition {
  padding-top: 20px;
  padding-bottom: 5px;
  font-size: 1rem;
}

.comentario {
  overflow-y: scroll;
  padding: 10px;
  width: 100%;
  height: 645px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: #ececec;
  border: 1px solid #cecece;
  align-items: center;
  align-content: center;
}

.chatboxRight {
  background: #bdcbd4;
  border: 1px solid #94a4ad;
  width: 70%;
  border-radius: 0.2em;
  position: relative;
  padding: 10px;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-left: 5%;
  float: left;
}

.chatboxRight p {
  margin: 0;
}

.chatboxLeft {
  background: #ffffff;
  width: 70%;
  border-radius: 0.2em;
  position: relative;
  padding: 10px;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-right: 5%;
  border: 1px solid #c5c5c5;
  float: right;
}

.chatboxLeft p {
  margin: 0;
}

.dataHora {
  width: 100%;
  color: #69767e;
  text-align: right;
  padding-right: 10px;
  margin-top: 10px;
}

.chaveOcorrencia {
  background: rgb(241, 242, 243);
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  margin: 0;
}

.valorOcorrencia {
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  margin: 0;
}

.solicitante {
  width: 49%;
  display: flex;
  border: 1px solid #b9b9b9;
  border-radius: 3px;
}

.tipo {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.divider {
  border-bottom: 1px dotted #b9b9b9;
}

.chaveSoicitante {
  width: 30%;
  background: rgb(241, 242, 243);
}

.valorSoicitante {
  width: 70%;
}

.title {
  width: 100%;
  font-weight: 700;
}

.subTitle {
  width: 100%;
}

.cardSpace {
  width: 50%;
}

.card {
  margin: 10px 0px 10px 0px;
  width: 100%;
  background: #fff;
  display: flex;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  box-shadow: 1px 2px 2px #888888;
  transition: 0.5s;
}

.iconTitulo {
  width: 30%;
  align-items: center;
  text-align: center;
}

.icon {
  position: relative;
  top: 30%;
  align-content: center;
  height: 40px;
  transition: 0.5s;
}

.card:hover .icon {
  height: 50px;
  color: var(--corPrincipal);
}

.titulo {
  font-size: 16px;
  font-weight: 500;
  color: var(--corFundo4);
  position: relative;
  top: 40%;
  align-content: center;
  transition: 0.5s;
}

.card:hover .titulo {
  color: var(--corComplementarMaisClara);
  top: 33%;
  font-size: 18px;
  font-weight: 700;
}

.conteudo {
  margin: 10px;
}

.ant-btn-primary {
  background: #008442 !important;
  border-color: #008442 !important;
  display: flex;
}

.botoes {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.botaoCompartilhar {
  border-radius: 10px !important;
  display: flex;
  float: left;
}

@media (max-width: 620px) {
  .botoes {
    float: left;
  }
}

@media (max-width: 800px) {
  .card-01 {
    flex: 100%;
    max-width: 600px;
  }

  .subtitle-card {
    flex: 100%;
    max-width: 600px;
  }
}

@media (max-width: 1200px) {
  .campos {
    width: 100%;
  }

  .campoMap {
    margin-top: 10px;
    width: 100%;
  }
}

@media screen and (min-width: 500px) {
  .title-card {
    font-size: calc(16px + (8 / 1200) * 100 * 1vw);
  }
}

